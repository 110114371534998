<template>
  <div>
    <div class="header_message">
      <span>更新周期：2小时</span
      ><span class="updata_times">上次更新：{{ updataTime }}</span>
    </div>
    <a-table
      :columns="columns"
      :data-source="listData"
      :pagination="false"
      :rowKey="(record) => record.id"
      :loading="loading"
    >
      <span slot="hot_level" slot-scope="hot_level">
        {{ hot_level | changeNum }}
      </span>
      <span slot="heat_rise_value" slot-scope="heat_rise_value">
        {{ heat_rise_value | changeNum }}
      </span>
      <span slot="id" slot-scope="text, record">
        <a @click="() => openVideo(record.aweme_url)" style="display:block">观看视频</a>
        <a @click="() => openDetail(record.aweme_id,record.sentence_id)" style="display:block">查看详情</a>
      </span>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
  </div>
</template>
<script>
// import { createNamespacedHelpers } from 'vuex'
// const { mapState, mapMutations, mapActions } = createNamespacedHelpers('hot_topic')
const columns = [
  {
    dataIndex: "position",
    align: "center",
    title: "排名",
  },
  {
    dataIndex: "word",
    align: "center",
    title: "热词",
  },
  {
    title: "热度值",
    dataIndex: "hot_value",
    align: "center",
    scopedSlots: { customRender: "hot_level" },
  },
  {
    title: "24小时上升",
    dataIndex: "operation_value",
    align: "center",
    scopedSlots: { customRender: "heat_rise_value" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 120,
  },
];

export default {
  data() {
    return {
      columns,
      listData: [],
      loading: false,
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      updataTime: "",
    };
  },
  computed: {},
  mounted() {
    this.getChallengeList(1, 10);
  },
  methods: {
    // 获取列表数据
    getChallengeList(current, page) {
      this.loading = true;
      let payload = {
        size: page,
        current,
      };
      this.$api.core.Polymers.challenge(payload)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.listData = res.data;
            this.pagination = res.pagination;
            this.updataTime = res.data[0].job_time;
          }
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getChallengeList(current, pageSize);
    },
    // 查看详情
    openDetail(id,sentence_id){
      console.log('传入id',id)
      this.$router.push({
        name:'polymers:challenge_detail',
        query:{
          id,
          sentence:sentence_id
        }
      })
    },
    openVideo(url) {
      if (url) {
        window.open(url);
      } else {
        this.$message.warning("链接不存在");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header_message {
  font-size: 15px;
  font-weight: 500;
  margin: 8px 0;
  text-align: right;
}
.updata_times {
  display: inline-block;
  margin: 0 26px;
}
</style>